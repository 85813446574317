body {
  font-family: "Montserrat", sans-serif;
  background: #f8f8f8;
  color: #202124;
}
p {
  font-size: 14px;
}
a:hover {
  color: #dfbf68;
}
:focus {
  outline: none;
}
button:focus{
  outline: none;
}
.btn-uno {
  color: #fff;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+1,ffffff+38,e1c169+65,bb9357+100 */
  background: rgb(255, 255, 255); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 1%,
    rgba(255, 255, 255, 1) 38%,
    rgba(225, 193, 105, 1) 65%,
    rgba(187, 147, 87, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 1%,
    rgba(255, 255, 255, 1) 38%,
    rgba(225, 193, 105, 1) 65%,
    rgba(187, 147, 87, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 1%,
    rgba(255, 255, 255, 1) 38%,
    rgba(225, 193, 105, 1) 65%,
    rgba(187, 147, 87, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#bb9357',GradientType=0 ); /* IE6-9 */

  padding: 10px 40px;
  border-radius: 30px;
  text-align: center;
  border: none;
  font-size: 13px;
  background-size: auto 300%;
  transition: all 0.3s;
  background-position: center bottom;
}
.btn-uno:hover {
  color: #bc9457 !important;
  background-position: top center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}
.text-gold {
  color: #dfbf68;
}
.titulo-xl {
  font-size: 32px;
  font-weight: 700;
}
.titulo {
  font-size: 19px;
  font-weight: 700;
}
.subtitulo-dorado {
  color: #dfbf68;
  font-size: 24px;
  margin: 0;
}
.form-control{
  border-radius: 0;
  box-shadow: none;
}
header .video-fix video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.pleca-center {
  width: 20px;
  height: 4px;
  background: rgb(225, 193, 105); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(225, 193, 105, 1) 0%,
    rgba(187, 147, 87, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(225, 193, 105, 1) 0%,
    rgba(187, 147, 87, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(225, 193, 105, 1) 0%,
    rgba(187, 147, 87, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e1c169', endColorstr='#bb9357',GradientType=0 ); /* IE6-9 */
  margin: -15px auto 15px;
  display: table;
}
.pleca {
  width: 20px;
  height: 4px;
  background: rgb(225, 193, 105); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(225, 193, 105, 1) 0%,
    rgba(187, 147, 87, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(225, 193, 105, 1) 0%,
    rgba(187, 147, 87, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(225, 193, 105, 1) 0%,
    rgba(187, 147, 87, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e1c169', endColorstr='#bb9357',GradientType=0 ); /* IE6-9 */
  margin: -15px 0 15px;
}
.section-gurus {
  background-image: url(https://mts.org.mx/assets/images/bg-circulos-1.jpg);
}
.guru-card-back {
  background-color: #e8dbb1;
  width: 93%;
  height: 100%;
  position: absolute;
  top: -15px;
  left: 0;
}
.guru-card {
  background-color: #fff;
  width: 100%;
  background-image: url(https://mts.org.mx/assets/images/mandala1.svg);
  position: relative;
  padding: 20px 30px;
  background-repeat: no-repeat;
  background-size: 59%;
  background-position-x: 100%;
  height: 350px;
  transition: all 0.3s;
  box-shadow: 2px 14px 30px rgba(0, 0, 0, 0.2);
}
.guru-card:hover {
  color: #fff;
  background-color: #4d5155;
}
.guru-foto {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 43%;
  max-width: 270px;
  transition: all 0.3s;
}
.guru-card:hover .guru-foto {
  width: 44%;
}
.libro1 {
  width: 100%;
  max-width: 120px;
  transition: all 0.3s;
}
.nombre-libro1 {
  margin: 10px 0 5px;
  height: 60px;
}
.card-darks {
  background: #4d5155;
  color: #fff;
  padding: 30px 60px;
}
.section-titulo {
  background: -moz-linear-gradient(
    top,
    rgba(38, 45, 63, 1) 0%,
    rgba(38, 45, 63, 1) 80%,
    rgba(38, 45, 63, 0) 80.1%,
    rgba(38, 45, 63, 0) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(38, 45, 63, 1) 0%,
    rgba(38, 45, 63, 1) 80%,
    rgba(38, 45, 63, 0) 80.1%,
    rgba(38, 45, 63, 0) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(38, 45, 63, 1) 0%,
    rgba(38, 45, 63, 1) 80%,
    rgba(38, 45, 63, 0) 80.1%,
    rgba(38, 45, 63, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#262d3f', endColorstr='#00262d3f',GradientType=0 ); /* IE6-9 */
}
.titulo-container {
  background-color: #ffffff;
  background-image: url(https://mts.org.mx/assets/images/titulo-bg.jpg);
  background-position: 50%;
  background-size: cover;
  text-align: center;
  padding: 60px 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 5;
}
.titulo-pagina {
  font-size: 32px;
}
.titulo-container p {
  margin: 0;
}
.libro-sugerido {
  color: #202124;
}
.libro-sugerido:hover {
  text-decoration: none;
  color: #4d5155;
}
.libro-sugerido:hover .libro1 {
  transform: scale(1.05) translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}
.rating {
  width: 70px;
  margin-bottom: 10px;
}
.bs-rating-star{
  font-size: 50px;
  color: #d2d2d2;
}
.bs-rating-star.active{
  color: #fbd543;
}
ul.lista {
  font-size: 14px;
  color: #202124;
  line-height: 1.5;
  margin-top: 15px;
}
ul.lista li {
  color: #3c3c3c;
  list-style: none;
  margin: 0 0 5px 0.5em;
  padding: 0;
  position: relative;
}
ul.lista li::before {
  color: #c39d5b;
  content: "\2022";
  display: inline-block;
  font-size: 2em;
  left: -1em;
  position: absolute;
  top: -0.5em;
}
q{
  font-size: 14px;
  font-style: italic;
}
.nota-contenido p{
  font-size: 16px;
  line-height: 1.7;
}
.boton-mute{
  position: absolute;
  top: 22px;
  right: 80px ;
  z-index: 100;
  opacity: .6;
}
.boton-play{
  position: absolute;
  top: 22px;
  right: 80px ;
  z-index: 100;
  opacity: .6;
}
.icorep{
  width: 32px;
  opacity: .7;
}
.video-texto a{
  font-size: 12px;
  line-height: 1;
  color: #dfbf68;
  font-weight: 600;
}
.video-texto a:hover{
  color: #dfbf68;
  text-decoration: none;
  cursor: pointer;
}
.iti{
  width: 100%;
}
.iti.separate-dial-code input{
  width: 100%;
  border: solid 1px #bbb;
  padding: 6px 0;
}

.country-dropdown.dropdown-menu{
 width: 380px !important;
}
.iti__country-name{
  font-size: 13px;
}
.redes{
  color: #eac159;
  margin-top: 20px;
  display: block;
  filter: drop-shadow(0px 0px 2px rgba(0,0,0,.9));
}
.redes img{
width: 30px;

}

@media (max-width: 1199.98px) {
  .boton-play{
    top: 19px;

  }
  .boton-play{
    top: 47px;
    right: 72px ;
  }
}

@media (max-width: 991.98px) {
  .titulo-pagina {
    font-size: 28px;
  }
  .titulo-descripcion{
    font-size: 13px;
  }
  .boton-mute{
    top: 47px;
    right: 72px
  }
  
}

@media (max-width: 767.98px) {
  .titulo-pagina {
    font-size: 24px;
  }
  .container{
    max-width: 700px;
  }
 
  .container{
    max-width: 700px !important;
  }
  .titulo-container{
    width: 100%;
    margin: auto
  }

}

@media (max-width: 575.98px) {
  .titulo-xl {
    text-align: center;
  }
  .pleca{
    margin: -15px auto 15px;
  }
  .titulo-pagina {
    font-size: 22px;
  }
  .titulo-descripcion{
    font-size: 13px;
  }
  .nota-contenido p{
    font-size: 15px;
    line-height: 1.5;
  }
  .country-dropdown.dropdown-menu {
    width: 300px !important;
  }
}
@media (max-width: 420px) {
  .titulo-xl {
    font-size: 26px;
  }
  .titulo-pagina {
    font-size: 20px;
    font-weight: 600;
  }
  .titulo-descripcion{
    font-size: 12px;
  }
  .titulo-container{
    padding: 40px 0;
  }
  .titulo-container p{
    display: none;
  }
  .nota-contenido p{
    font-size: 14px;
  }
  .country-dropdown.dropdown-menu {
    width: 280px !important;
  }
  
}
